
import Vue, { PropType, VueConstructor } from "vue"
import axios, { AxiosResponse, AxiosError } from "axios"
import Pagination from '@/components/Pagination.vue'
import { DataTableHeader } from "vuetify"
import { state, mutations } from "@/plugins/state"
import { EventBus } from "@/plugins/eventBus"
import { SnackBarData } from "@/components/Snackbar.vue"
import WorkerChronology from "@/components/WorkerChronology.vue"
import DateFormatMixin from "@/mixins/DateFormatMixin.vue"

interface Item {
  id: number,
  deleted_at?: null | string
}
export interface SelectedItem {
  [key: number]: boolean
}
interface FilterList {
  [key: string]: string[]
}
interface FilterValues {
  [key: string]: string | {[key: string]: string}
}
export interface Filter {
  label: string,
  type: "text-field" | "boolean" | "toggle" | "select" | "autocomplete" | "date" | "number",
  url?: string,
  text: string|null,
  value: string|null,
  search?: string, // parameter key use for search on API call
  allowsOnlyOneDate?: boolean, // on "date" filter only, allow from and to picking
  items?: Array<any>,
  multiple?: boolean, // if select need multiple selection
  prependValue?: string, // generated url value prepend before filter value
  appendValue?: string, // generated url value append after filter value
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof DateFormatMixin>>).extend({
  name: "ListParagon",
  components: {
    Pagination,
    WorkerChronology
  },
  mixins: [DateFormatMixin],
  props: {
    title: {
      type: String,
      default: 'list'
    },
    filters: {
      type: Array as PropType<Filter[]>,
      default: []
    },
    name: {
      type: String,
    },
    headers: {
      type: Array,
      required: true
    },
    headersSplitPane: {
      type: Array,
      required: true
    },
    url: {
      type: String,
      default: null
    },
    isSelection: {
      type: Boolean,
      default: true
    }, // to show selection (admin)
    order: {
      type: Object,
      default: null
    },
    displaySelect: {
      type: Boolean,
      default: true
    },
    openModal: {
      type: Boolean,
      default: false
    },
    dataForUpdate: {
      type: Object as PropType<{ url: string, success: string, data: {[key: string]: string | number}, action: string}>,
      default: null
    },
    disabledSubmit: {
      type: Boolean,
      default: false
    },
    resetSelection: {
      type: Boolean,
      default: false
    },
    previousSelection: {
      type: Object,
      default: function() {
          return {}
      }
    }
  },
  data: function () {
    return {
      loading: false,
      items: [] as Item[],
      itemsIds: [] as number[],
      page: 1,
      lastPage: 1,
      contentRange: "",
      view: "split-pane",
      options: {
        sortBy: [""],
        sortDesc: [Boolean]
      },
      sort: "",
      sortText: null as string | null,
      desc: "",
      itemFocus: {} as Item,
      selected: {} as SelectedItem,
      selectedPage: false,
      pageIndeterminate: false,
      allIndeterminate: false,
      allSelected: false,
      drawer: false,
      openPanel: null as number | null,
      filterList: {} as FilterList,
      filterValues: {} as FilterValues,
      totalNoFilter: 0,
      firstLoad: true,
      autocompleteItems: {} as {[key: string]: {[key: string]: string}}, // object fetched from the api are stored here
      autocompleteSearch: {} as {[key: string]: string},
      autocompleteFocus: '',
      chips: {} as {[key: string]: string[]},
      isLoading: false, // Temporary loading variable. When isLoading is set to true, all autocomplete will trigger a loading animation
      modals: [],
      fromToDate: {} as {[key: string]: string},
      modalDisplay: false,
      modalValidateLoader: false,
      showFocus: null as number|null,
      pageView: 1, // data to save page id when view is changing
      pageChangeView: false // data to prevent page changement when view is changing
    }
  },
  watch: {
    previousSelection: {
      handler() {
        this.selected = this.$props.previousSelection
      },
      deep: true,
      immediate: true
    },
    selected: function(): void {
      this.$emit('selectedItems', this.selected)
    },
    resetSelection: function(): void {
      this.emptySelection()
    },
    page: function(): void {
      this.getData()
    },
    options: function(): void {
      this.sort = this.options.sortBy.length > 0 ? this.options.sortBy[0] : ""
      this.desc = this.options.sortDesc[0] ? this.options.sortBy[0] : ""
      // set custom sorting text if configured on header
      this.sortText = null
      if (this.sort != "") {
        let sortedHeader = this.headers.find((h: any) => h.value === this.sort) as {sortText: string}
        if (sortedHeader?.sortText) {
          this.sortText = sortedHeader.sortText
        }
      }
      // If a filter is applied, we reset the pagination to the first page of the results
      // Only if it's not a view changement
      if (!this.pageChangeView) {
        this.page = 1
      }
      this.pageChangeView = false
      this.getData()
    },
    view: function(): void {
      this.pageView = this.page
      this.pageChangeView = true
      if(this.name !== undefined && this.title !== undefined) mutations.setView(this.name, this.title, this.view)
    },
    autocompleteSearch: {
      handler: function (val) {
        // Since :search-input.sync does not accept functions as value, we built this work around to set the right key and url to the autocomplete search
        for (const filter of this.filters as Filter[]) {
          if (filter.label === this.autocompleteFocus && Boolean(val[filter.label])) {
            // We fetch the data for the focused filter and if values are being searched
            this.searchAutocomplete(val[filter.label], filter.label, filter.url as string, filter.search)
          }
        }
      },
      deep: true
    },
    url: function(): void {
      // reset first load in order to recalculate full total
      this.firstLoad = true
      // reload data when url is changing
      if (this.page !== 1) {
        this.page = 1 // will trigger getData()
      } else {
        this.getData()
      }
    },
    openModal: function(): void {
      if(Object.entries(this.selected).length){
        this.modalDisplay = true
      } else {
        EventBus.$emit('snackbar',{message: this.$vuetify.lang.t('$vuetify.empty_selection'), color: 'error'})
      }
    }
  },
  mounted() {
    EventBus.$on('notification-view', (event: {view: string}) => {
      this.view = event.view
    })
    // check if route come from workerUpdate alias notification
    if(state.view?.[this.name]?.[this.title] !== undefined && this.$route?.name !== "WorkerUpdate") {
      this.view = state.view[this.name][this.title]
    }
    if (this.view === "split-pane" && window.innerWidth < 600) this.view = "list"
    EventBus.$on('listParagonDataReload', () => {
      this.getData()
    })
    EventBus.$on('availabilityWorker', (data: string) => {
      this.view = 'chronology'
      this.getData(this.url + '&names='+data)
    })
    if (this.$props.previousSelection && Object.keys(this.$props.previousSelection).length > 0){
      this.selected = this.$props.previousSelection
    }
  },
  destroyed() {
    EventBus.$off('listParagonDataReload')
    EventBus.$off('availabilityWorker')
    EventBus.$off('notification-view')
  },
  methods: {
    isSelected(item: Item) {
      return item.id === this.showFocus ? 'isSelected' : ''
    },
    checkDrawerClickOutside() {
      let htmlSelectedElement: HTMLElement[] = []
      document.querySelectorAll('[role="listbox"]').forEach((el: any) => { htmlSelectedElement.push(el)})
      document.querySelectorAll('.drawer-open-click').forEach((el: any) => { htmlSelectedElement.push(el)})
      return htmlSelectedElement
    },
    closePanel() {
      this.drawer = false
    },
    closeModal() {
      this.modalDisplay = false
    },
    getData(path: string|null = null): void {
      if (this.url == null) {
        return
      }
      this.loading = true
      // On the first mount of the component,
      // We get the total count of items without applying filters
      if (this.firstLoad) {
        // We stop this behavior after the first mount for performance optimization
        this.firstLoad = false
        axios.get(this.url)
        .then((response: AxiosResponse) => {
          this.totalNoFilter = parseInt(response.headers["content-range"].split('/')[1])
        })
        .catch((e: AxiosError) => {
          EventBus.$emit('snackbar',{axiosError: e})
          this.loading = false
        })
      }
      axios.get(path ?? this.url, {
          params: this.buildQueryParams()
        })
        .then((response: AxiosResponse) => {
          this.contentRange = response.headers["content-range"]
          let match = /page=(\d+)[^>]*>;\s*rel="last"/m.exec(
            response.headers.link
          )
          // By default, we only have one page (even if no results are returned)
          this.lastPage = 1
          if (match?.[1] && parseInt(match[1]) > 1) {
            // if the last page returned by the api is > 1, we set it to the returned number
            this.lastPage = parseInt(match[1])
          }

          if (this.page > this.lastPage) {
            // if the current page is higher than the last page, we set it to the last page
            this.page = this.lastPage
          }

          this.loading = false
          // add new index property with index value to keep sort data into full calendar
          this.items = response.data.map(
            (item: Item, index: number) =>{ return {...item, ...{'index': index}}})
          this.itemFocus = this.items[0] ?? {}
          this.itemsIds = this.items.map((item): number => item.id)
          this.checkPageSelection()
          // detect if we arrive on split pane view landing page
          let splitUrl = window.location.pathname.split('/')
          if (this.view === 'split-pane') {
            let pathId = splitUrl[splitUrl.length -1].match(/^\d+$/)
            let id = null 
            if (pathId === null && response.data.length > 1) {
              id = response.data[0].id
            } else if (pathId !== null) {
              id = pathId[0]
            } else if (this.itemFocus.id !== undefined) {
              id = this.itemFocus.id
            }
            this.displayItemDetails(id)
          }
        })
        .catch((e: AxiosError) => {
          EventBus.$emit('snackbar',{axiosError: e})
          this.loading = false
        })
    },
    changePage(page: number) {
      this.page = page
    },
    displayItem(row: Item) {
      this.itemFocus = row
    },
    selectItem(id: number) {
      if (!this.selected[id]) {
        this.selected = { ...this.selected, [id] : true }
        this.checkPageSelection()
      } else {
        this.selectedPage = false
        this.allSelected = false
        this.selected = {...(delete this.selected[id] && this.selected)}
        this.checkPageSelection()
      }
    },
    selectPage() {
      let curentSelection = {...this.selected}
      if (this.selectedPage) {
        for (let id of this.itemsIds) {
          curentSelection[id] = true
        }
      } else {
        for (let id of this.itemsIds) {
          delete curentSelection[id]
        }
      }
      // Reassign value to the data for watchers based on the prop
      this.selected = {...curentSelection}
      this.checkPageSelection()
    },
    emptySelection() {
      this.selected = {}
      this.checkPageSelection()
      this.$emit('selectedItems', this.selected)
    },
    selectAllFilteredItems() {
      if (this.allSelected) {
        this.loading = true
        const params = this.buildQueryParams(true)
        let curentSelection = {...this.selected}
        axios.get(this.url, {
          params: params
        })
        .then((response: AxiosResponse) => {
          this.loading = false
          // We select all the items returned by the API
          for (const item of response.data) {
            if (item['id']) {
              // need to path like v1/worker
              curentSelection[item['id']] = true
            } else {
              curentSelection[item] = true
            }
          }
          this.itemFocus = this.items[0]
          this.selected = {...curentSelection}
          this.checkPageSelection()
          this.$emit('selectedItems', this.selected)
        })
        .catch((e: AxiosError) => {
          EventBus.$emit('snackbar',{axiosError: e})
          this.loading = false
        })
      } else {
        // remove all selected in current filter
        this.removeAllFilteredItems()
        this.checkPageSelection()
      }
    },
    removeAllFilteredItems(): void {
      const params = this.buildQueryParams(true)
      this.loading = true
      let curentSelection = {...this.selected}
      axios.get(this.url, {
        params: params
      })
      .then((response: AxiosResponse) => {
        // We remove from selection all the items returned by the API
        for (const item of response.data) {
          let id = item.id ? item.id : item
          if(Object.keys(curentSelection).find(select => select === id.toString() )) {
            delete curentSelection[id]
          }
        }
        this.selected = {...curentSelection}
        this.checkPageSelection()
      })
      .catch((e: AxiosError) => {
        EventBus.$emit('snackbar',{axiosError: e})
      }).finally(() =>{
        this.loading = false
      })
    },
    checkPageSelection(): void {
      let selectedInPage = 0
      let totalResults = parseInt(this.contentRange.split('/')[1])
      // iterating on all the ids of the items present on the current page
      for (const id of this.itemsIds) {
        // if one element on the page is selected
        if (id in this.selected) {
          selectedInPage++
        }
      }

      // check page selection
      if (selectedInPage === this.itemsIds.length && selectedInPage > 0) {
        // if the number of items select is equal to the total number of items on the page
        // the page is selected and not indeterminate
        this.selectedPage = true
        this.pageIndeterminate = false
      } else if (selectedInPage < this.itemsIds.length && selectedInPage > 0) {
        // if the number of items select is inferior to the total number of items on the page but items are selected
        // the page is not selected, it is indeterminate
        this.selectedPage = false
        this.pageIndeterminate = true
      } else { //if(selectedInPage === 0)
        // if no items are selected
        // the page is not selected, it is not indeterminate
        this.pageIndeterminate = false
        this.selectedPage = false

        // all is not selected and not indeterminate
        this.allSelected = false
        this.allIndeterminate = false
      }
      //check all items selection
      if (Object.keys(this.selected).length < totalResults && Object.keys(this.selected).length > 0) {
        // if the number of selected ids is set between 1 and the total results returned by the API
        // all is not selected but indeterminate
        this.allSelected = false
        this.allIndeterminate = true
      } else if (Object.keys(this.selected).length === totalResults && Object.keys(this.selected).length > 0){
        // if the number of selected ids is equals to the results total returned by the API
        // all is selected and not indeterminate
        this.allSelected = true
        this.allIndeterminate = false
      }
      this.$emit('selected', Object.keys(this.selected))
    },
    buildQueryParams(selectAll = false)
    {
      this.buildFilters()

      let params = {
        page: this.page,
        sort: this.sort,
        desc: this.desc,
        selectAll: selectAll
      } as {page: number, sort: string, desc: string, selectAll?: boolean}

      if (!this.isSelection) {
        delete params.selectAll
      }
      // If filters are found, we add them to the url's params
      for (const [key, values] of Object.entries(this.filterList)) {
      let filter = this.getFilterFromLabel(key)
      let cleansedValues = []
      // we escape commas from the value
      for (let value of values) {

        if (filter?.type === "text-field") {
          cleansedValues.push(value.replace(/,/g , "\\,"))
        } else {
            cleansedValues.push(value)
          }
        }
        Object.assign(params, {
          [key]: (filter?.prependValue ? filter?.prependValue : '') +
            cleansedValues.join() +
            (filter?.appendValue ? filter?.appendValue : '')
        })
      }
      return params
    },
    getFilterFromLabel(label: string): Filter | undefined
    {
      for (const f of this.filters as Filter[]) {
          if(f.label === label) {
            return f
          }
        }
    },
    formatDateFilterForURL(value: {[key: string]: string}, key: string)
    {
      if (value[0] === undefined && value[1] === undefined) {
        return "\0"
      } else if (value[0] !== undefined && value[1] !== undefined) {
        if (value[0] < value[1]) {
          return `${value[0]};${value[1]}`
        } else {
          return `${value[1]};${value[0]}`
        }
      } else if (value[0] !== undefined) {
        if (this.fromToDate[key] === "to") {
          return `;${value[0]}`
        } else {
          return `${value[0]};`
        }
      } else {
        return undefined
      }
    },
    formatDateFilterForChips(value: {[key: string]: string}, key: string)
    {
      if (value[0] === undefined && value[1] === undefined) {
        return this.$vuetify.lang.t('$vuetify.none')
      } else if (value[0] !== undefined && value[1] !== undefined) {
        if (value[0] < value[1]) {
          return this.localizeDate(value[0], false) + ' - ' + this.localizeDate(value[1], false)
        } else {
          return this.localizeDate(value[1], false) + ' - ' + this.localizeDate(value[0], false)
        }
      } else if (value[0] !== undefined) {
        if (this.fromToDate[key] === "to") {
          return this.$vuetify.lang.t('$vuetify.to') + ' ' + this.localizeDate(value[0], false)
        } else {
          return this.$vuetify.lang.t('$vuetify.from') + ' ' + this.localizeDate(value[0], false)
        }
      }
    },
    buildFilters()
    {
      for (const [key, value] of Object.entries(this.filterValues)) {
        let filter = this.getFilterFromLabel(key)

        if (filter == undefined || value === undefined || (!value && !['boolean', 'toggle'].includes(filter.type))) {
          // If no filter founded or no value is set, we wont register it in the filters
          continue
        }

        // If a filter is applied, we reset the pagination to the first page of the results
        this.page = 1
        // If the key is already present in the filterList
        const textField = this.filters.find(f => f.label === key)?.text as string
        const valueField = this.filters.find(f => f.label === key)?.value as string
        if (!(key in this.filterList)) {
          Object.assign(this.filterList, {[key]: []})
          Object.assign(this.chips, {[key]: []})
        }

        if (typeof value === 'object' && filter.type === "autocomplete") {
          if (Array.isArray(value)) {
            value.forEach((v: any) => {
              this.chips[key].push(v[textField])
              this.filterList[key].push(v[valueField])
            })
          } else {
            this.chips[key].push(value[textField])
            this.filterList[key].push(value[valueField])
          }
        } else if (typeof value === 'object' && filter.type === "date") {
          // WIP: Temporarily replace the values: The commented code will be useful when we have found a solution to search on several date ranges
          // this.chips[key].push(this.formatDateFilterForChips(value))
          // this.filterList[key].push(this.formatDateFilterForURL(value))
          Object.assign(this.chips, {[key]: [this.formatDateFilterForChips(value, key)]})
          Object.assign(this.filterList, {[key]: [this.formatDateFilterForURL(value, key)]})
        } else if (filter.type === 'boolean' || filter.type === 'toggle') {
          // selecting and deselecting toggle filter produce undefined value
          // on v-model despiste representing no filter value selected
          // so we can skip those filterValues
          Object.assign(this.filterList, {[key]: [this.filterValues[key]]})
          Object.assign(this.chips, {[key]: [
            // this code retrieve item text from value
            this.filters.find(f => f.label === key)?.items?.find(i => i.value === value).text
          ]})
        } else if (filter.type === "select") {
          // we add the new value to the array
          if (Array.isArray(value)) {
            value.forEach((selectData: string) => {
              this.filterList[key].push(selectData)
              let chipLabel = selectData
              this.chips[key].push(this.$vuetify.lang.t(`$vuetify.${chipLabel}`))
            })
          }
        } else if (filter.type === "number") {
          if (filter.value !== null && value !== "0") {
            this.chips[key] = [value + " km"]
            this.filterList[key] = [filter.value + ":" + value]
          }
        } else {
          this.filterList[key].push(value as string)
          this.chips[key].push(value as string)
        }
        // remove the duplicates for each filters and chips
        this.filterList[key] = [... new Set(this.filterList[key])]
        this.chips[key] = [... new Set(this.chips[key])]
        // we reset the inputs values
        delete this.filterValues[key]
        delete this.fromToDate[key]
      }
    },
    removeFilter(key: string, value: string): void
    {
      /**
       * checks on chips to remove chip and filter
       * since chips and filterList are somewhat equal (same filter key, array indexes, but different values of the same object),
       * we can use the index of the chips[key] array to delete an item in the filterList[key] array
      */
      if (key in this.chips) {
        if (this.chips[key].includes(value)) {
          let index = this.chips[key].indexOf(value)
          this.chips[key].splice(index, 1)
          this.filterList[key].splice(index, 1)
        }
        if(!this.chips[key].length && !this.filterList[key].length) {
          delete this.chips[key]
          delete this.filterList[key]
        }
        // If a filter is applied, we reset the pagination to the first page of the results
        this.page = 1
      }
      // Fetch the data with the removed filter
      this.getData()
    },
    check(event: string, label: string) {
      this.filterList[label] = [event]
      this.chips[label] = [event]
      this.$emit(label, event)
    },
    clearFilters(): void {
      for (const key in this.filterList) {
        delete this.chips[key]
        delete this.filterList[key]
        delete this.filterValues[key]
      }
      // need to reset filterValue for boolean filter type
      // Fetch the data with the removed filters
      this.getData()
    },
    searchAutocomplete(val: string, label: string, url: string, search = 'search'): void {
      this.isLoading = true
      let params = {} as {[key: string]: string}
      params[search] = val
      axios.get(url, {
        params: params
      })
      .then((response: AxiosResponse) => {
        Object.assign(this.autocompleteItems, {[label]: response.data})
      })
      .catch((e: AxiosError) => {
        EventBus.$emit('snackbar',{axiosError: e})
      }).finally(() => {
        this.isLoading = false
      })
    },
    selectedItems(){
      // return list of selected data (useful to get data from parent)
      return this.selected
    },
    deleteItems() {
      let prepDelete = this.buildDeletionParams()
      let ids = prepDelete.ids
      let params = prepDelete.params
      let errors = [] as {[key: string]: string}[]
      // prepare all API calls
      let calls: Promise<AxiosResponse>[] = []
      for (const ids of params) {
        calls.push(
          axios.delete(this.url, {
            params: {
              id: ids
            }
          })
        )
      }
      // perform all API calls
      Promise.all(calls).then((results) => {
        let messages = ""
        results.forEach((result: AxiosResponse) => {
          for (const [key, value] of Object.entries(result.data)) {
            messages += key + ": " + value + " \n"
          }
        })
        // By default, the deletion was successful, we build error messages if errors are found
        let snackbar: SnackBarData  = {message: this.$vuetify.lang.t('$vuetify.deletion_successful')}
        if (messages) {
          snackbar = {message: this.$vuetify.lang.t('$vuetify.deletion_partial') + ": \n" + messages, color: 'warning'}
        }
        EventBus.$emit('snackbar',snackbar)
      })
      .catch((e: AxiosError) => {
        errors.push(e.response?.data)
        EventBus.$emit('snackbar',{axiosError: e})
      }).finally(() => {
          for (const id of ids) {
            // If a selected item is not in errors, we removed it from selection since it has been deleted
            if (!errors.find(error => Object.keys(error)[0] === id)) {
              delete this.selected[parseInt(id)]
            }
        }
        this.getData()
      })
    },
    buildDeletionParams() {
      let ids = [] as string[]
      let params = [] as string[]
      let index = 0
      for (const item of Object.keys(this.selected)) {
        ids.push(item)
        params[index] = params[index]? params[index] + item + "," : item + ","
        if (params[index].length + (this.url + "?id=").length  >= 2048) {
          // get the last occurrence of a comma to remove the last id and integrate it in the next request
          let lastIndex = params[index].lastIndexOf(',')+1
          // the param is full, we store the next ids in a new index
          params[index+1] += params[index].slice(lastIndex)
          index++
        }
      }
      return {ids: ids, params: params}
    },
    displayItemDetails(id: number) {
      // We use the route path instead of the route name to simplify the process of showing the details of an item. We just use the current url and add an /:id at the end
      // Another way to achieve this would be to use the notation `router.push({name: RouteName, params:{id: id}})` and give the RouteName to this component through a prop
      // We split the different part of the url in an array
      let currentRouteElements = this.$router.currentRoute.path.split('/')
      let currentRoute = ""
      let routeToGoTo = ""
      // We check if the last part of the url is not a number (the id of the focused element)
      if(isNaN(Number(currentRouteElements[currentRouteElements.length - 1]))) {
        currentRoute = currentRouteElements.join('/')
        // We go to the route leading to the details of the focused element
        routeToGoTo = `${currentRoute}/${id}`
      } else {
      // If the last part of the url is a number, it means that an element has already it's details displayed.
        // To display the details of the newly focused element, me remove the id of the previous focused item and replace it with the current one
        currentRouteElements.pop()
        currentRoute = currentRouteElements.join('/')
        routeToGoTo = `${currentRoute}/${id}`
      }
      if (this.$vuetify.breakpoint.mobile) {
        // We redirect to a different route if we are in mobile view
        const lastSlashIndex = routeToGoTo.lastIndexOf('/')
        routeToGoTo = routeToGoTo.substring(0,lastSlashIndex) + '/update' + routeToGoTo.substring(lastSlashIndex)
      }
      // show selected item into list
      this.showFocus = id
      if (routeToGoTo !==  window.location.pathname) {
        this.$router.push(routeToGoTo)
      }
    },
    redirectToSplitPane(id: number) {
      this.view = 'split-pane'
      this.displayItemDetails(id)
    },
    // NOTE - we return items as they came, AKA desactivating locale sorting on component
    // it prevents component sorting our data already sorted by our API
    desactivateLocaleSort(items: any[]) {
      return items
    },
    updateData() {
      // If no items are selected, we don't launch the request
      if (!Object.keys(this.selected).length) {
        EventBus.$emit('snackbar',{message: this.$vuetify.lang.t('$vuetify.empty_selection'), color: 'error'})
        return
      }
      this.modalValidateLoader = true
      this.createUpdateRequest()
      .then((response: AxiosResponse) => {
        let error = null
        if (response.data.length > 0) {
          error = 'warning'
        }
        EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.'+ this.$props.dataForUpdate.success), color: error })
      })
      .catch((e: AxiosError) => {
        EventBus.$emit('snackbar',{axiosError: e})
      })
      .finally(() => {
        this.modalValidateLoader = false
        this.modalDisplay = false
        this.$emit('resetModalFields', true)
        this.getData()
      })
    },
    createUpdateRequest() {
      if (this.dataForUpdate.action === 'get') {
        return axios.get(this.dataForUpdate.url, this.dataForUpdate.data)
      } else if(this.dataForUpdate.action === 'put') {
        return axios.put(this.dataForUpdate.url, this.dataForUpdate.data)
      } else if(this.dataForUpdate.action === 'post') {
        return axios.post(this.dataForUpdate.url, this.dataForUpdate.data)
      }
      return axios.delete(this.dataForUpdate.url, this.dataForUpdate.data)
    }
  },
  computed: {
    splitPaneHeaders: function() {
      return ([{
        text: this.$vuetify.lang.t("$vuetify.selection"),
        align: 'start',
        sortable: false,
        value: 'select'
      }] as Array<DataTableHeader>).concat(this.headersSplitPane as Array<DataTableHeader>)
    },
    listHeaders: function() {
      if (this.isSelection) {
        return ([{
          text: this.$vuetify.lang.t("$vuetify.selection"),
          align: 'start',
          sortable: false,
          value: 'select'
        }] as Array<DataTableHeader>).concat(this.headers as Array<DataTableHeader>)
      }
      return []
    }
  }
})
